@import "src/common";

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(72, 81, 102, 0.8);
  animation-name: appear;
  animation-duration: 100ms;
}

.modalTabs {
  padding-top: 0;
}

.modalDialog {
  width: 100%;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/
  background: white;
  border-radius: 4px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.2s;
  animation-name: slide-in;
  animation-duration: 0.1s;
  padding: 40px;
  @media (max-width: 767.9px) {
    padding: 16px;
    margin: 0 15px;
    max-width: calc(100% - 30px);
  }
}

.sm {
  max-width: 480px;
}

.md {
  max-width: 759px;
}

.modalHeader,
.modalFooter {
  display: flex;
  align-items: center;
}

.modalHeader {
  justify-content: space-between;
  margin-bottom: 32px;
  @media (max-width: 767.9px) {
    margin-bottom: 24px;
  }
}

.modalFooter {
  justify-content: center;
  margin-top: 32px;
  @media (max-width: 767.9px) {
    margin-top: 24px;
  }
}

.modalFooterCenter {
  justify-content: center;
}

.modalClose {
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  margin: 0 0 0 auto;
}

.modalWrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  overflow: auto;
  //overflow: visible;
}

.modalWrapper::-webkit-scrollbar {
  width: 0;
}

.modalWrapper::-webkit-scrollbar {
  width: 0;
}

.modalBody {
  //scrollbar-width: none;
  //-ms-overflow-style: none;
  //overflow: -moz-scrollbars-none;
  //overflow: auto;
  //overflow: visible;
  //padding-bottom: 48px;
}

//.modalBody::-webkit-scrollbar {
//  width: 0
//}
//
//.modalBody::-webkit-scrollbar {
//  width: 0
//}

.modalContent {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $gray;
}

.modalTitle {
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: $black;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}

.modalPbHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalPbClose {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  height: 32px;
  width: 32px;
}
