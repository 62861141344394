@import "src/common";

.wrapper {
  max-width: 400px;
}
.body {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.title {
  text-align: center;
}
.icon {
  margin: 0 auto;
  color: $yellow;
  width: 80px;
  height: 80px;
}
.text {
  text-align: center;
  font-size: 16px;
}
