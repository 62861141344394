@import "src/common";

.wrapPanel {
  position: relative;
  display: flex;
  align-items: center;
  webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767.9px) {
    width: 80px;
  }
}

.panel {
  background-color: #f2f7fa;
  border: 1px solid $border;
  border-radius: 4px;
  height: 48px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 14px;
  @media (max-width: 767.9px) {
    width: auto;
    margin-right: 0;
    padding-left: 0;
    min-width: 48px;
  }
}

.walletIcon {
  width: 24px;
  margin-right: 16px;
  margin-left: 16px;
  height: 24px;
  @media (max-width: 767.9px) {
    display: none;
  }
}

.statusTariff {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  @media (max-width: 767.9px) {
    display: none;
  }
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 4px 4px 0 0;
  border: 1px solid $border;
  position: relative;
  left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767.9px) {
    border: none;
    width: 46px;
    height: 46px;
    left: 0;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 24px;
  height: 24px;
  @media (max-width: 767.9px) {
    margin-left: 8px;
    width: 24px;
  }
}

.hidden {
  display: none;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.nowrap {
  white-space: nowrap;
}

// colors
.success {
  color: $green;
}
.warning {
  color: $yellow;
}
.danger {
  color: $red;
}

.base {
  color: $black;
}

.capitalize {
  text-transform: capitalize;
}
