@import "src/common";

$widthSidebarCollapsed: 80px;
$widthSidebar: 248px;

.layout {
  display: flex;
}
.sidebarWrap {
  flex-shrink: 0;
  width: $widthSidebar;
  position: sticky;
  z-index: 101;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    width: $widthSidebarCollapsed;
    overflow: hidden;
    transition: none;
  }
  @media (max-width: 767.9px) {
    width: 100%;
    position: fixed;
  }
}
.sidebarWrapCollapsed {
  width: $widthSidebarCollapsed;
  overflow: hidden;
  @include animationSidebar();
  @media (max-width: 767.9px) {
    display: block;
    width: 100%;
  }
}
.sidebarWrapCollapsedTablet {
  @media (min-width: 768px) and (max-width: 1439.9px) {
    width: $widthSidebarCollapsed;
    overflow: hidden;
    @include animationSidebar();
    display: block;
  }
}
.sidebarWrapCollapsedMobile {
  @media (max-width: 767.9px) {
    display: none;
  }
}

.sidebarWrap.sidebarWrapCollapsed + .rightWrap {
  width: calc(100% - #{$widthSidebarCollapsed});
}

.rightWrap {
  position: relative;
  right: 0;
  width: calc(100% - #{$widthSidebar});
  padding-top: 80px;
  min-height: 100vh;
  background: $background;
  display: flex;
  flex-direction: column;
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    transition: none;
    width: calc(100% - #{$widthSidebarCollapsed});
  }
  @media (max-width: 767.9px) {
    padding-left: 0;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.rightWrapCollapsed {
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    transition: none;
  }
  @media (max-width: 767.9px) {
    padding-left: 0;
    transition: none;
  }
}
.rightWrapCollapsedTablet {
  @media (min-width: 768px) and (max-width: 1439.9px) {
    transition: none;
  }
}
.rightPart {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  @media (max-width: 1439.9px) {
    padding: 24px;
    overflow: hidden;
  }
  @media (max-width: 767.9px) {
    padding: 24px 16px;
  }
}

.layoutAuth {
  display: flex;
  background: $white;
}

.landing {
  display: flex;
}

.sidebarAuth {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 248px;
  flex-shrink: 0;
  flex-grow: 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  // background-image: url("../../images/auth.png");
  padding: 24px 0 0 16px;

  @media (max-width: 1439.9px) {
    display: none;
  }
}
.rightAuth {
  padding: 220px 100px 100px 376px;
  @media (max-width: 1439.9px) {
    padding: 0 39px 100px;
  }
  @media (max-width: 767.9px) {
    padding: 0 15px 15px;
  }
}

.mobileLogo {
  display: none;
  @media (max-width: 1439.9px) {
    display: block;
    padding-top: 24px;
    margin-bottom: 169px;
  }
  @media (max-width: 767.9px) {
    margin-bottom: 70px;
  }
}

.headerAuth {
  margin-bottom: 148px;
}

.container {
  max-width: 1920px;
  padding: 0 240px;
  width: 100%;
  margin: auto;
  @media (max-width: 1919px) {
    padding: 0 120px;
  }

  @media (max-width: 1440px) {
    padding: 0 64px;
  }

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 769px) {
    padding: 0 60px;
  }

  @media (max-width: 560px) {
    padding: 0 20px;
  }
}

.header {
  background-position: bottom left;
  background-repeat: no-repeat;
}

.headerBanner {
  text-align: center;
  padding: 98px 0;
  font-family: "Outfit", sans-serif;
  @media (max-width: 1440px) {
    padding: 80px 0;
  }
  @media (max-width: 1120px) {
    padding: 64px 0;
  }
  @media (max-width: 768px) {
    padding: 48px 0;
  }
  h1 {
    font-size: 64px;
    margin: 0;
    margin-bottom: 30px;
    line-height: 80px;
    @media (max-width: 1440px) {
      font-size: 58px;
    }
    @media (max-width: 1120px) {
      font-size: 48px;
      line-height: 64px;
    }
    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
      margin: 0;
    }
  }
  p {
    margin: 0;
    display: inline-block;
    max-width: 943px;
    font-size: 18px;
    line-height: 32px;
    color: #6e7e9d;
    font-family: "Outfit", sans-serif;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.wrapper {
  padding: 60px 0 0;
}

.containerFluid {
  max-width: 100%;
}

.backgroundColorWhite {
  background-color: #ffffff;
}
