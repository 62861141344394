@import "src/common";

.link {
  font-family: Source Sans Pro, sans-serif;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  color: $blue;
  padding: 0 1px;
  text-decoration: none;
  position: relative;

  &:hover {
    color: $blue-hover;
    &::after {
      opacity: 1;
    }
  }
  &:focus {
    color: $blue;
    box-shadow: 0 0 0 1px $blue;
    &::after {
      opacity: 0;
    }
  }

  &::after {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: "";
    height: 1px;
    background-color: $blue-hover;
  }
}

.icon {
  vertical-align: middle;
}

.iconLeft {
  margin-right: 4px;
}

.iconRight {
  margin-left: 4px;
}

.icon svg {
  margin-top: 4px;
}

.md {
  font-size: 14px;
  line-height: 24px;
}

.lg {
  font-size: 18px;
}
