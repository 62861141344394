@import "src/common";

.icon {
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
}
.bottom {
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}

.wrap {
  max-width: 340px;
}
