.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  background-size: 100% !important;
}

.letter {
  line-height: 1;
  font-size: 59%;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
}

.test {
  display: flex;
  column-gap: 20px;
}
