@import "src/common";

.mainImage {
  padding: 0 210px;
  max-height: 539px;
  align-self: center;
  width: 100%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 120px 0;
}

.black {
  color: #000;
}

.text {
  margin: 0;
  font: 400 18px/32px "Source Sans Pro";
  color: $gray;
}

.textBold {
  margin: 0;
  font: 500 20px/32px "Outfit";
  color: $black;
}

.imageSm {
  display: none;
}

@media (max-width: 1439.9px) {
  .mainImage {
    padding: 0 138px;
  }

  .heading {
    font-size: 28px;
  }
  .text {
    font-size: 16px;
    line-height: 28px;
  }
}
@media (max-width: 1023.8px) {
  .mainImage {
    padding: 0 143px;
  }
  .imageSm {
    display: block;
    margin: 0 auto;
    max-width: 263px;
  }
  .textWrapper {
    padding: 53px 0;
    max-width: 481px;
    margin: 0 auto;
    text-align: center;
  }
  .text {
    font-size: 16px;
    line-height: 24px;
  }
  .heading {
    font-size: 28px;
  }
}
@media (max-width: 767.8px) {
  .heading {
    font-size: 20px;
    line-height: 30px;
  }
  .text,
  .textBold {
    font-size: 14px;
    line-height: 24px;
  }
}
