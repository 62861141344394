@import "src/common";

.container {
  background: $white;
  border: 1px dashed $gray-light;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 32px;

  &.active {
    border: 1px dashed $gray;
  }

  @media (max-width: 1199.9px) {
    display: none;
  }
}

.container_support {
  background: $white;
  border: 1px dashed $border;
  border-radius: 5px;
  padding: 0;
  margin-bottom: 48px;
}

.icon {
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  @media (max-width: 767.9px) {
    display: none;
  }
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: $black;
  font-family: "Source Sans Pro", sans-serif;

  @media (max-width: 767.9px) {
    display: none;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.container_support .buttonContainer {
  display: block;
}

.buttonContainerDisabled,
.buttonContainerDisabled * {
  pointer-events: none;
}

.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
  padding-right: 0;
}

.wrap {
  margin-bottom: 23px;
}

.wrap:last-child {
  margin-bottom: 0;
}

.progress {
  background: $border;
  width: 100%;
  height: 4px;
  border-radius: 100px;
  overflow: hidden;
}

.innerProgress {
  background: $green;
  height: 8px;
  width: 0px;
}

.fileRow {
  display: flex;
  align-items: center;
}

.fileIcon {
  margin-right: 24px;
  height: 24px;
  @media(max-width: 767.9px) {
    margin-right: 12px;
  }
}

.fileName {
  color: $black;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 220px;
  white-space: nowrap;
  @media(max-width: 400px) {
    display: none;
  }
}

.fileNameMobile {
  color: $black;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 220px;
  display: none;
  white-space: nowrap;
  @media(max-width: 400px) {
    display: block;
  }
}

.fileSize {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 16px;
}

.filePercent {
  @media (max-width: 767px) {
    display: none;
  }
}

.removeFile {
  height: 20px;

  & svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.grow {
  flex-grow: 1;
}

.content {
  padding: 53px;
  @media (max-width: 768px) {
    padding: 20px 53px;
  }
}

.mobileSizeUploadRow {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  display: none;
  @media (max-width: 1199.9px) {
    display: flex;
  }
}


.uploadMobileWrapper {
  display: none;
  @media (max-width: 1199.9px) {
    display: block;
  }
}


.checkrowWrapper {

  //margin-bottom: 24px;
}

.checkrow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1B2A47;
}

.button {
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  padding: 0 32px;
  background: #ffffff;
  color: #1b2a47;
  font-weight: 600;
}
