@import "scss/breaks";
@import "scss/fonts.scss";

$blue: #41a8ed;
$blue-menu-bg: #158fe0;
$blue-hover: #0a7ac3;
$blue-active: #1383cd;
$blue-icon-default: #a1d3f7;
$blue-bg: #f5fbff;
$background: #f2f7fa;
$black: #1b2a47;
$gray: #6e7e9d;
$gray-light: #d4d7e0;
$border: #d3e4ee;
$border-hover: #b6d6e9;
$white: #ffffff;
$red: #fe5f55;
$red-hover: #cb0e01;
$red-active: #df1001;
$green: #47c281;
$green-hover: #2e8a59;
$green-active: #339963;
$yellow: #f0b90b;
$orange: #dc6b41;
$red-bg: #ffe6e4;
$yellow-bg: #fff5d6;
$green-bg: #def5e9;

$transparent: transparent;
$transparentHover: transparent;
$sm: 767.9px;

$md: 768px;
$lg: 1440px;
$xl: 1920px;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #f4f6fd;
  font-family: "Source Sans Pro", sans-serif;
}

html {
  scroll-behavior: smooth;
}

@mixin animationSidebar() {
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pageTitleCommon {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: $black;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767.9px) {
    width: 100%;
    display: block;
  }
}

.titleRow {
  width: 100%;
  @media (max-width: 767.9px) {
    margin-bottom: 32px;
  }
}

.headerRowCenter {
  align-items: center;
}

@mixin placeholder() {
  ::-webkit-input-placeholder {
    color: $black;
  }

  :-moz-placeholder {
    color: $black;
  }

  ::-moz-placeholder {
    color: $black;
  }

  :-ms-input-placeholder {
    color: $black;
  }

  ::-ms-input-placeholder {
    color: $black;
  }

  ::placeholder {
    color: $black;
  }
}

.labelInput {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $black;
  margin-bottom: 4px;
}

.wrapper {
  position: relative;
}

.cursor {
  &:hover {
    cursor: pointer;
  }
}

.block {
  background: white;
  border: 1px solid $border;
  border-radius: 4px;
}

.blockHeader {
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid $border;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767.9px) {
    padding: 16px 24px;
    height: auto;
  }
}

.blockTitle {
  color: $black;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.headerBlockTabsTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 374.9px) {
    padding: 0 15px;
  }
}

.headerBlockTabs {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: $black;
  @media (max-width: 767.9px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.tabs {
  display: flex;

  & .tab:last-child {
    margin-right: 0;
  }
}

.tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  width: 140px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -2.5px;
  border-bottom: 3px solid transparent;
  margin-right: 5px;
  color: $gray;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767.9px) {
    width: 80px;
    height: 60px;
    font-size: 14px;
    line-height: 20px;
  }
}

.tab.tabActive {
  color: $blue;
  border-bottom: 3px solid $blue;
}