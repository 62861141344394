@import "src/common";

.containerUpload {
  width: 308px;
  position: fixed;
  z-index: 9999999999;
  bottom: 20px;
  right: 20px;
  background-color: white;
  @media (max-width: 500px) {
    width: calc(100% - 40px);
  }
}

.topPart {
  background: $blue;
  border-radius: 4px 4px 0 0;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & * {
    color: $white;
    fill: $white;
    font-family: "Outfit", sans-serif;
  }
}

.topLeft {
  display: flex;
  align-items: center;
}

.topRight {
  display: flex;
  align-items: center;
}

.topText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.percentageBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 24px;
  background: $white;
  border-radius: 30px;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-right: 16px;
  color: $blue;
}

.bottomPart {
  padding: 20px;
  background-color: white;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomLeft {
  display: flex;
  align-items: center;
}

.filesLeft {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #5A5D65;
}

.bottomRight {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #5A5D65;
}

.iconFile {
  margin-right: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.progress {
  margin-top: 8px;
  background: $background;
  width: 100%;
  height: 4px;
  border-radius: 100px;
  overflow: hidden;
}

.innerProgress {
  background: $green;
  height: 8px;
  width: 0;
}

.iconBlock {
  &:hover {
    cursor: pointer;
  }
}