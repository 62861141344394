@import "src/common";


.container {
  position: relative;
  width: 76px;
  margin-right: 32px;

  & *:hover {
    cursor: pointer;
  }

  &:hover {
    cursor: pointer;
  }
}

.wrap {
  display: flex;
  align-items: center;
}

.labelInput {
  margin-right: 14px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
  color: #1B2A47;
  font-family: "Source Sans Pro";

  @media (max-width: 767.9px) {
    display: none;
  }
}

.input {
  width: 100%;
  outline: none;
  position: relative;
  color: #1B2A47;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  height: 32px;
  padding: 0 35px 0 16px;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid $border;
  border-radius: 3px;
  transition: all 0.2s linear;
  background: white;
  text-align: center;
  @include placeholder()
}

.list {
  position: absolute;
  z-index: 2;
  //top: calc(100% + 8px);
  //top: calc(-400% - 8px);
  bottom: calc(30px);
  width: 100%;
  min-height: 30px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid $border;
  padding: 7px 0;
  border-radius: 3px;
}

.listItemElement {
  padding: 0 10px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-family: "Source Sans Pro";
  max-width: 100%;
  overflow: hidden;
  color: $black;
  background: white;

  &:hover {
    background: #F5FBFF;
    color: #41A8ED;
  }

  &:focus {
    outline: none !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.listItemElementActive {
  background: $white;
}

.arrowIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  padding-right: 6px;

  svg {
    margin-top: 4px;
    padding-right: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}

.error {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}

.errorText {
  color: $red;
  font-size: 12px;
  line-height: 24px;
  font-family: "Outfit", sans-serif;
  font-weight: normal;
}

.errIcon {
  margin-right: 8px;
}
