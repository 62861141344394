@import "src/common";

.button {
  font-family: "Source Sans Pro", sans-serif;
  color: white;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 5px;
  box-shadow: none !important;
  border: none !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.buttonprimary {
  background: $green;

  &:hover {
    background: $green-hover;
  }
}

.buttondisabledprimary {
  background: $green-bg;
  cursor: auto !important;

  &:hover {
    background: $green-bg;
  }
}

.buttondisabledsecondary {
  background: $border;
  cursor: auto !important;

  &:hover {
    background: $border;
  }
}

.buttonaccent {
  background: $red;

  &:hover {
    background: $red-hover;
  }
}

.buttonoutlined {
  background: transparent;
  border: 1px solid $blue !important;
  color: $blue;
  border-radius: 4px;

  &:hover {
    background: $blue;
    color: #fff;
  }
}

.buttonsecondary {
  background: $blue;

  &:hover {
    background: $blue-hover;
  }
}

.buttontransparent {
  background: $transparent;

  &:hover {
    background: $transparentHover;
  }
}

.buttondisabledtransparent {
  cursor: auto !important;

  &:hover {
    background: $transparent;
  }
}

.buttonadditional {
  background: $background;
  color: $gray;

  &:hover {
    background: $border-hover;
  }
}

.buttongray {
  background: $gray;
  color: $white;

  &:hover {
    background: $border-hover;
  }
}

.buttonlightgray {
  background: $white;
  //background: gray;
  color: $black;

  &:hover {
    background: $border-hover;
  }
}

.buttondisabled {
  background: $white;
  cursor: auto !important;
  color: $black;
}

.buttonaccent {
  background: $red;
  color: white;

  &:hover {
    background: $red-hover;
  }
}

.buttondisabledadditional {
  cursor: auto;

  &:hover {
    background: $white;
    cursor: auto;
  }
}

.buttonsmall {
  width: 100%;
}

.buttonbig {
  width: 168px;
  height: 48px;
  @media (max-width: 767.9px) {
    width: 100%;
    padding: 6px 20px;
  }
}

.buttonlarge {
  max-width: 300px;
  width: 100%;
  height: 48px;
  @media (max-width: 767.9px) {
    max-width: none;
    width: 100%;
    padding: 6px 20px;
  }
}

.buttonextrasmall {
  height: 32px;
  width: 140px;
  font-size: 12px;
  line-height: 24px;
}

.buttontiny {
  width: auto;
  height: 16px;
  font-size: 12px;
  line-height: 24px;
}

.buttonlink {
  display: inline;
  background-color: transparent;
  color: $blue;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  padding: 0;
  width: auto;
  vertical-align: center;

  &:hover {
    background-color: transparent;
  }
}

.buttonlinkIcon {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  background-color: transparent;
  color: $blue;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 32px;
  padding: 0;
  width: auto;
  vertical-align: center;

  &:hover {
    background-color: transparent;
    color: $blue-hover;
    text-decoration: underline;

    svg {
      color: $blue-hover;
    }
  }
}

.buttonnone {
}

.buttongift {
  width: 270px;
  font-size: 14px;
  margin-bottom: 20px;
}

.buttonform {
  display: block !important;
  height: 72px;
  width: 100%;
  font-size: 18px;
  line-height: 32px;
  padding: 0 32px;
  box-shadow: 0 16px 40px rgba(0, 35, 102, 0.1);
  border-radius: 60px;
  @media (max-width: 768px) {
    height: 56px;
  }
}

.buttonfullwidth {
  display: block !important;
  height: 32px;
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  padding: 0 32px;
}

.buttonsquare {
  display: block !important;
  width: 48px;
  height: 48px;
}

.asyncBtn {
  position: relative;
}

.asyncBtnSpin {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
}

.icon {
  line-height: 0;
  margin-left: 10px;
  display: inline-block;
  position: relative;

  svg {
    width: 15px;
    height: 15px;
  }
}
