@import "./variables.scss";

.checkout {

  width: 398px;
  align-self: center;
  margin: auto;

  @include w768 {

    width: 100%;

  }

  &__body {

    @include w768 {

      width: 404px;
      margin: auto;

    }

    @include w360 {

      width: 100%;

    }

  }

  &__title {

    margin-bottom: 16px;

    @include w1024h600 {

      margin-bottom: 10px;

    }

    @include w768 {

      text-align: center;
      margin-bottom: 8px;

    }

  }

  &__text {

    font-size: 24px;
    line-height: 40px;
    color: var(--grey);
    margin-bottom: 40px;

    @include w1440 {

      font-size: 16px;
      line-height: 24px;

    }

    @include w1024h600 {

      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;

    }

    @include w768 {

      margin-bottom: 32px;
      text-align: center;

    }

  }

  &__text-20 {

    font-size: 20px;
    line-height: 2;
    color: var(--grey);

    @include w1440 {

      font-size: 16px;
      line-height: 28px;

    }

    @include w1024h600 {

      font-size: 16px;
      line-height: 24px;

    }

  }

  &__content {

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 504px;

    @include w1440h1024 {

      min-height: 432px;

    }

    @include w1024h600 {

      min-height: 372px;

    }

    @include w768 {

      min-height: 0;

    }

  }

  &__content &__title {

    margin: 40px 0;

    @include w1440 {

      margin: 16px 0;

    }

    @include w1024 {

      margin: 10px 0;

    }

    @include w360 {

      margin: 8px 0;

    }

  }

  &__content &__text {

    min-height: 200px;
    margin-bottom: 20px;

    @include w1440h1024 {

      min-height: 72px;
      font-size: 20px;
      line-height: 2;

    }

    @include w1024h600 {

      font-size: 20px;
      line-height: 2;

    }

  }

  &__foot {

    display: flex;
    margin-top: 50px;

    @include w1440h1024 {

      margin-top: 40px;

    }

    @include w1024h600 {

      margin-top: 24px;

    }

    @include w768 {

      margin-top: 32px;

    }

  }

  &__btn-next {

    flex-grow: 1;

  }

  &__btn-prev {

    margin-right: 16px;

  }

  &:invalid &__btn-submit {

    pointer-events: none;
    background-color: var(--border);

  }

}