@import "./variables.scss";
.visible-md,
.visible-sm {

  display: none;

}

.center {

  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;

}

@include desktop {

}

@include w1440 {

  :root {

    --space: 140px;

  }

  .center {

    width: 1200px;

  }

}

@include w1024 {

  :root {

    --indent: 50px;
    --gap: 8px;
    --space: 80px;

  }

  .center {

    width: 864px;

  }

}

@include w768 {

  :root {

    --indent: 60px;
    --space: 50px;

  }

  .center {

    width: 648px;

  }

  .visible-sm {

    display: block;

  }

}

@include w360 {

  :root {

    --indent: 20px;
    --space: 70px;

  }

  .center {

    width: 468px;

  }

}

@media (max-width: 359px) {

  :root {

    --indent: 10px;

  }

}