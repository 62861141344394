@import "src/common";

.btn {
  margin-right: 32px;

  @media (max-width: $md) {
    margin-right: 16px;
  }
}

.serviceModal {
  max-width: 800px;
}

.serviceModalBody {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.spinner {
  color: black;
}

.table {
  border-collapse: collapse;

  td,
  th {
    border: 1px solid black;
  }
}
