.pageWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.pageRow {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.input {
  display: flex;
  flex-direction: column;
}
