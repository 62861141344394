@import "src/common";

.item{
  display: inline;
  text-decoration: none;
  color: $blue;
  &:hover{
    color: $blue-hover;
    text-decoration: underline;
    cursor: pointer;
  }
}