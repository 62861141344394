@import "src/common";

.wrapHeader {
  width: calc(100% - 248px);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  margin-left: 248px;
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    width: calc(100% - 80px);
    margin-left: 80px;
    transition: none;
  }
  @media (max-width: 767.9px) {
    margin-left: 0;
    width: 100%;
  }
}

.wrapHeaderCollapsed {
  margin-left: 80px;
  width: calc(100% - 80px);
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    width: calc(100% - 80px);
    margin-left: 80px;
    transition: none;
  }
  @media (max-width: 767.9px) {
    width: 100%;
    margin-left: 0;
    transition: none;
  }
}

.headerContent {
  width: 100%;
  border-bottom: 1px solid $border;
  background-color: white;
}

.header {
  position: relative;
  margin: 0 auto;
  z-index: 100;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  @media (max-width: 1439.9px) {
    padding: 16px 24px;
    gap: 24px;
  }
  @media (max-width: 767.9px) {
    padding: 16px;
    gap: 0;
  }
}

.menuIcon {
  margin-right: 32px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1439.9px) {
    display: none;
    margin-right: 0;
  }
  @media (max-width: 767.9px) {
    display: block;
    margin-right: 16px;
  }

  &.menuIconSearchOpen {
    @media (max-width: 767.9px) and (min-width: 375px) {
      margin-right: 14px;
    }
  }
}

.search {
  width: 360px;
  margin-right: auto;
  transition: all 0.2s;
  @media (max-width: 1439.9px) {
    width: 100%;
  }
  @media (max-width: 767.9px) {
    display: none;
  }
}

.mobileSearch {
  transition: none;
  width: 48px;
  height: 48px;
  padding: 11px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid $border;
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    flex-shrink: 0;
  }

  display: none !important;
  @media (max-width: 767.9px) {
    display: flex !important;
  }
  @media (max-width: 767.9px) {
    margin-right: auto !important;
  }
}

.icon {
  height: 24px;
}

.mobileSearch.mobileSearchOpen {
  transition: all 0.2s;
  flex-grow: 1;
  padding: 0 12px;
  margin-right: 0;

  & svg {
    margin-left: 10px;
  }
}

.wrapBell {
  margin-right: 32px;
  @media (max-width: 1439.9px) {
    margin-right: 24px;
  }
}

.wrapIcon {
  width: 48px;
  height: 48px;
  padding: 11px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid $border;
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1439.9px) {
    margin-right: 0;
  }
  @media (max-width: 767.9px) {
    margin-right: 23px;
  }

  &:hover {
    cursor: pointer;
  }
}

.hidden {
  display: none;
}

.dropdownPanel {
  background: white;
  filter: drop-shadow(4px 4px 16px #eef0f4);
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  right: 38px;
  top: calc(100% + 8px);
  z-index: 101;
  width: 260px;
  max-height: 80vh;
  overflow-y: auto;

  & .dropdownBlock:last-child {
    border-bottom: none;
  }

  @media (max-width: 767.9px) {
    right: 32px;
  }
  @media (max-width: 374.9px) {
    right: 0;
  }
}

.dropdownBlock {
  padding: 16px 0 16px 0;
  border-bottom: 1px solid $border;
}

.dropdownItem {
  position: relative;
  padding: 0 0 0;
  display: flex;
  align-items: center;

  .dropdownItemTextBlock {
    padding-left: 56px;
    padding-right: 24px;
    width: 100%;
    align-items: flex-start;

    .userName {
      font-weight: 600;
      //styleName: Text 14 Semibold;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      color: $black;
    }

    .userEmail {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      /* Gray */
      color: $gray;
    }
  }
}

.wallet_address {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: $gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.dropdownItemWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  &:hover {
    cursor: pointer;

    .text {
      color: $blue;
    }
  }
}

.smallIcon {
  position: absolute;
  left: 16px;
  top: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $black;
  text-decoration: none;
}

.textTariff {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $gray;
}

.progressWrapper {
  width: 100%;
  margin-bottom: 16px;
}

.progressWrapper:last-child {
  margin-bottom: 0px;
}

.progressText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.tariffNameWrapper {
  display: flex;
  justify-content: flex-start;
}

.tariffName {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.tariffNameTrial {
  color: $green;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.tariffMoney {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $blue;
  vertical-align: center;
}

.tariffPeriod {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: $gray-light;
}

.progressType {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $gray;
  text-transform: capitalize;
}

.progressVolume {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $black;
}

.progressLineBackground {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: #d3e4ee;
  border-radius: 4px;
  width: 100%;
  height: 10px;
}

.progressLine {
  background-color: $green;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.periodRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.periodText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $gray;
  white-space: nowrap;
}

.periodDate {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $black;
}

.alert {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #dc6b41;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.link {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #41a8ed;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.userName {
  margin-bottom: 4px;
}

.textTariff {
  margin-bottom: 4px;
  text-transform: capitalize;
}

.flexRowBox {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
