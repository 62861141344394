.container{
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  @media (max-width: 499.9px) {
   flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    gap: 12px;
  }
}
