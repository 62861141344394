@import "./variables.scss";
.header {

  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  display: flex;

  @include w1440 {

    top: 50px;

  }

  @include w1024h600 {

    top: 36px;

  }

  @include w768 {

    display: none;

  }

  &__left {

    width: 50%;
    display: flex;
    justify-content: center;

  }

  &__logo {

    @include w1440 {

      img {

        width: 123px;
        height: 24px;

      }

    }

    @include w1024h600 {

      img {

        width: 123px;
        height: 24px;

      }

    }

  }

}