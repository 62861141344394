@import "src/common";

.container {
  display: flex;
  flex-direction: column;
}
.label {
  display: flex;
  justify-content: space-between;
  font: Source Sans Pro 14px/24px;
  margin-bottom: 4px;

  &Text {
    color: $gray;
  }

  &Units {
    font-weight: 600;
    color: $gray;
  }
}

.bar {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 4px;
  overflow: hidden;
  background: #d3e4ee;

  & .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 4px;

    &Normal {
      background: #47c281;
    }
    &OverNormal2x {
      background: #f0b90b;
    }
    &OverNormal3x {
      background: #dc6b41;
    }
  }
}
