@import "src/common";

.container{
  width: 100%;
  &:hover{
    cursor: pointer;
  }
}

.wrapperButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

@media (max-width: 500px) {
  .container {
    width: 100%;
  }
}
