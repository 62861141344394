@import "src/common";

.badge {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  height: 36px;
  font-family: Source Sans Pro;
  font-size: 16px;
  color: #fff;
}
.active {
  background: linear-gradient(270.05deg, #158fe0 0%, #47c281 100%);
}
.end {
  background: $red;
}
.willExpire {
  background: $yellow;
}

.badgeTariffName {
  opacity: 0.8;
}
.textBlock {
  display: flex;
  column-gap: 16px;
}
.bold {
  font-weight: 600;
}
