@import "src/common";

.errorBlock {
  color: $black;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}
.errors {
  margin-bottom: 32px;
  text-align: center;
}

.icon {
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
}
.bottomErrors {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.wrap {
  max-width: 340px;
}
