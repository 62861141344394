@import "./variables.scss";
/* .h1 и .h2 при необходимости можн отказаться */

.wrapper {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.h1 {

  font: 600 36px/40px 'Outfit';

  @include w1440h1024 {

    font-size: 28px;

  }

  @include w1024h600 {

    font-size: 24px;
    line-height: 32px;

  }

  @include w360 {

    font-size: 24px;
    line-height: 32px;

  }

}

.h2 {

  font: 600 28px/40px 'Outfit';

  @include w1440 {

    font-size: 28px;

  }

  @include w1024h600 {

    font-size: 24px;
    line-height: 32px;

  }

  @include w360 {

    font-size: 18px;
    line-height: 32px;

  }

}


/*
-----------------------------------------------------------------------------*/

.main {

  flex-shrink: 0;
  flex-grow: 1;
  display: flex;

  &__left {

    width: 50%;
    display: flex;
    justify-content: flex-end;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1266' height='1080' viewBox='0 0 1266 1080'%3E%3Cg fill='%2341A8ED' fill-opacity='.09'%3E%3Cpath d='M1266 0v1080H0L1266 0Z'/%3E%3Cpath d='M1266 264V1080H308L1266 264Z'/%3E%3C/g%3E%3C/svg%3E");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    padding: var(--indent) 0 80px;

    @include w1024h600 {

      padding: 80px 0 40px;

    }

    @include w768 {

      display: none;

    }

  }

  &__right {

    width: 50%;
    display: flex;
    background-color: #fff;
    padding: var(--indent) 0;

    @include w1024h600 {

      padding: 50px 0;

    }

    @include w768 {

      width: 100%;
      display: block;
      padding: var(--indent);
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='768' height='688' fill='none' viewBox='0 0 768 688'%3E%3Cpath fill='%2341A8ED' fill-opacity='.09' d='M768 0v688H0L768 0Z'/%3E%3Cpath fill='%2341A8ED' fill-opacity='.09' d='M768 168.25V688H187.06L768 168.25Z'/%3E%3C/svg%3E");

    }

    @include w360 {


    }

  }

}


/* .hide и .visuallyhidden при необходимости можн отказаться */

.hide {

  display: none !important;

}

.visuallyhidden {

  position: absolute !important;

  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;

  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;

}