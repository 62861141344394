@import "src/common";

.container {
  background: $white;
  border: 1px solid $border;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.left {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 16px;
  height: 24px;
}

.text {
  font-size: 18px;
  line-height: 32px;
  color: $black;
  @media (max-width: 767.9px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.link {
  color: $blue;
  text-decoration: none;

  &:hover {
    color: $blue-hover;
    text-decoration: underline;
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}