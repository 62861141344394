@import "src/common";

.wrapCard {
  width: 100%;
  //margin: 12px;
  @media (max-width: 1023.9px) {
    //width: 50%;

  }
  @media (max-width: 767.9px) {
    width: 100%;
    margin: 0;
  }
}

.cardsRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  //margin: -12px -12px 32px;
  margin-bottom: 32px;

  @media(max-width: 1023.9px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(max-width: 767.9px) {
    margin: 0;
    display: block;
    .wrapCard {
      margin-bottom: 32px;
      display: block;
    }
  }
}


.chartWrapper {
  position: relative;
}

.analyticsAnchor {
  position: absolute;
  top: -104px;
}

.link {
  font-weight: 600;
}

.dashboardCardSkeleton {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  height: 148px;
}

.chartStorageUsageSkeleton {
  height: 706px;
  width: 100%;
  border-radius: 4px;
}

.supportSkeleton {
  height: 66px;
  margin-bottom: 32px;
  border-radius: 4px;
}