@import "src/common";

.wrapper {
  max-width: 660px;
  @media (max-width: 767.9px) {
    max-width: 500px;
  }
}

.checkrow {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 29px;

}

.size,
.textSize {
  display: inline-block;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 769.9px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
}

.buttonsRow {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  @media (max-width: 769.9px) {
    gap: 16px;
  }

  & button {
    width: 150px;
    @media (max-width: 769.9px) {
      width: 100%;

    }
  }

  @media (max-width: 767.9px) {
    & .wrapBtn1,
    & .wrapBtn2 {
      width: 100%;
      margin-right: 0;
    }
    & .wrapBtn2 {
      button {
        width: 100% !important;
      }
    }
  }


  @media (max-width: 499.9px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 8px;
    & .wrapBtn1,
    & .wrapBtn2 {
      width: 100%;
      margin-right: 0;


    }
    & .wrapBtn2 {
      button {
        width: 100% !important;
      }
    }
  }
}

.filesList {
  margin-top: 46px;
   @media(max-width: 1199.9px) {
    order: 2;
     margin-top: 0;
  }
}

.warningWrap {
  margin-top: 32px;
}

.warningText {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $gray;
  max-width: 327px;
  margin-bottom: 32px;
}

.warningRow {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.warningLabel {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $black;
  margin-left: 16px;
}

.helpText {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  max-width: 172px;
  @media (max-width: 767.9px) {
    max-width: none;
    text-align: center;
  }
}

.container {
  @media(max-width: 1199.9px) {
    display: flex;
    flex-direction: column;
  }
}

.dragWrapper {
  @media(max-width: 1199.9px) {
    order: 1;
  }
}

.footerWrapper {
  @media(max-width: 1199.9px) {
    order: 0;
    margin-bottom: 32px;
  }
}

.paginationWrapper {
  @media(max-width: 1199.9px) {
    order: 3;
  }
}