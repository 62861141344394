@import "src/common";

.modal {
  max-width: 490px;
  padding: 40px;
}
.modalBody {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 4px;

  &Footer {
    display: flex;
    column-gap: 16px;
  }

  &Btn {
    width: 100%;
  }

  &Text {
    font: 400 14px/24px Source Sans Pro;
    color: $gray;
  }
}

.reactHidden {
  width: 100%;
  height: 8px;
}

.card {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: $blue-bg;
  border: 1px solid $border;
  border-radius: 4px;

  &Left {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &Charge {
    color: $blue;
    font: 600 16px/24px Outfit;
    margin: 0;
  }

  &Text {
    font: 400 14px/24px Outfit;
    color: $gray;
    margin: 0;
  }
  &Heading {
    font: 600 16px/24px Outfit;
    color: $black;
    margin: 0;
  }
}

.bankCard {
  display: flex;
  align-items: center;
  border: 1px solid $border;
  border-radius: 4px;
  height: 64px;
  padding: 0 16px;
  font: 600 14px/24px Source Sans Pro;
  color: $black;

  &Title {
    font: 600 16px/24px Outfit;
    color: $black;
    margin: 0;
  }
  &Info {
    display: flex;
    column-gap: 16px;
  }
  &Expire {
    flex-grow: 1;
    text-align: end;
  }
}

.error {
  color: $red;
}
