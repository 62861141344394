@import "mixins";

// Source Sans Pro
@include font-face(
  'Source Sans Pro',
  font/SourceSansPro/SourceSansPro-Regular/SourceSansPro-Regular,
  400,
  normal,
  eot woff2 woff ttf
);
@include font-face(
  'Source Sans Pro',
  font/SourceSansPro/SourceSansPro-SemiBold/SourceSansPro-SemiBold,
  600,
  normal,
  eot woff2 woff ttf
);

// Outfit
@include font-face(
  'Outfit',
  font/Outfit/Outfit-Bold/Outfit-Bold,
  700,
  normal,
  eot woff2 woff ttf
);
@include font-face(
  'Outfit',
  font/Outfit/Outfit-ExtraBold/Outfit-ExtraBold,
  800,
  normal,
  eot woff2 woff ttf
);
@include font-face(
  'Outfit',
  font/Outfit/Outfit-Medium/Outfit-Medium,
  500,
  normal,
  eot woff2 woff ttf
);
@include font-face(
  'Outfit',
  font/Outfit/Outfit-Regular/Outfit-Regular,
  400,
  normal,
  eot woff2 woff ttf
);
@include font-face(
  'Outfit',
  font/Outfit/Outfit-SemiBold/Outfit-SemiBold,
  600,
  normal,
  eot woff2 woff ttf
);
