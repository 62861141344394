:root {

  --color: #1b2a47;
  --grey: #6e7e9d;
  --blue: #41a8ed;
  --pink: #e56399;
  --green: #3bb273;
  --error: #ff3e13;
  --bg: #fafafa;
  --border: #d4d7e0;

  --transitionDefault: .3s;

  --indent: 100px;
  --gap: 20px;
  --space: 40px;

}


@mixin desktop {

  @media (min-width: 1250px) {

    @content;

  }

}

@mixin w1440h1024 {

  @media (min-width: 1601px) and (max-height: 999px), (max-width: 1600px) {

    @content;

  }

}

@mixin w1440 {

  @media (max-width: 1600px) {

    @content;

  }

}

@mixin w1024h600 {

  @media (min-width: 1024px) and (max-height: 880px) {

    @content;

  }

}

@mixin w1024 {

  @media (max-width: 1300px) {

    @content;

  }

}

@mixin w768 {

  @media (max-width: 1023px) {

    @content;

  }

}

@mixin w360 {

  @media (max-width: 524px) {

    @content;

  }

}