@import "src/common";

.input {
  height: 48px;
  width: 100%;
  padding: 15px 45px 15px 65px;
  background-color: $background;
  border-radius: 4px;
  border: 1px solid $border;
  box-shadow: none;
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $black;
}

.fakeInput{
  display: flex;
  align-items: center;
}

.input:focus {
  background-color: $white;
}

.mobileInput {
  font-family: "Outfit", sans-serif;
  width: 100%;
  height: 30px;
  padding: 0;
  background-color: $background;
  border-radius: 0;
  border: none;
  box-shadow: none;
  outline: none;
}

.mobileInputHidden {
  display: none;
}

.input::-webkit-input-placeholder, .mobileInput::-webkit-input-placeholder {
  color: $gray;
}

.input::-moz-placeholder, .mobileInput::-moz-placeholder {
  color: $gray;
}

.input:-moz-placeholder, .mobileInput:-moz-placeholder {
  color: $gray;
}

.input:-ms-input-placeholder, .mobileInput:-ms-input-placeholder {
  color: $gray;
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 12px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767.9px) {
    display: none;
  }
}

.searchIconClose {
  position: absolute;
  right: 16px;
  top: 12px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }
}

.container {
  position: relative;
  width: 100%;
  max-width: 360px;
}