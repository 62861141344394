@import "src/common";

.wrapper {
  margin-top: 64px;
  margin-bottom: 100px;
}

.statusText {
  border-radius: 4px;
  width: 65px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding: 4px 0;
}

.success {
  background: #def5e9;
  color: #3bb273;
}

.pending {
  background: #fff5d6;
  color: #f0b90b;
}

.failed {
  background: #ffe6e4;
  color: #fe5f55;
}

.wrapperEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 0 0 4px 4px;
  background-color: $white;
  border-left: 1px solid $border;
  border-bottom: 1px solid $border;
  border-right: 1px solid $border;
}

.contentEmpty {
  display: flex;
  gap: 10px;
  align-items: center;
}

.textEmpty {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $gray;
}

.pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: $blue;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.tdAmount {
  display: flex;
  align-items: center;
  column-gap: 70px;
}

.payBtn {
  width: 65px;
  height: 24px;
  font-weight: 600;
}
.payBtnIcon {
  width: 13px !important;
  height: 13px !important;
}

.modal {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 478px;
  margin: 5px;
}

.buttons {
  display: flex;
  column-gap: 10px;
}
