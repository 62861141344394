@import "src/common";

.sidebar {
  background-color: $blue-menu-bg;
  height: 100vh;
  padding: 0 16px;
  overflow-y: auto;
  overflow-x: hidden;

  display: none;
  @media (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: 767.9px) {
    padding: 60px 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.sidebarTablet {
  display: none;
  @media (min-width: 768px) and (max-width: 1439.9px) {
    display: flex;
    flex-direction: column;
  }
}

.sidebarMobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.sidebarCollapsedTablet {
  @media (min-width: 768px) and (max-width: 1439.9px) {
    padding-top: 24px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.sidebarCollapsedTablet::-webkit-scrollbar {
  width: 0;
}

.sidebarCollapsedDesktop {
  @media (min-width: 1440px) {
    padding-top: 15px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.logotype {
  margin-bottom: 32px;
  max-height: 24px;
  display: none;
  padding: 24px 0 48px;
  padding-left: 8px;
  cursor: pointer;

  & svg {
    max-height: 40px;
  }

  @media (min-width: 768px) and (max-width: 1439.9px) {
    margin-bottom: 43px;
    max-height: none;
    padding-left: 12px;
  }
  @media (max-width: 767.9px) {
    margin-bottom: 156px;
  }
}

.logotypeFlex {
  display: flex;
  justify-content: center;
}
@media (max-width: 1439.9px) {
  .sidebarCollapsedTablet {
    .logotype {
      padding: 0;
    }
  }
}

@media (max-width: 769.9px) {
  .sidebarCollapsedTablet {
    .logotype {
      padding: 0;
    }
  }
}

.logotypeCollapsed {
  width: 90px;
  padding: 0;
  padding-left: 17px;
  max-height: 48px;
  margin-bottom: 16px;
}

.logotype.logotypeCommon {
  display: block;
}

.logotype.logotypeCollapsed {
  display: block;
}

@media (max-width: 767.9px) {
  .logotype {
    position: absolute;
    top: 24px;
    left: 24px;
    padding: 0;
    margin: 0;
  }
  .logotype.logotypeCollapsed {
    display: none;
  }
  .logotype.logotypeCommon {
    display: none;
  }
  .logotype.mobileCollapsed {
    display: block;
  }
  .logotype.mobileCommon {
    display: none;
  }
}

.items {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 767.9px) {
    justify-content: center;
    flex-grow: 0;
  }
}

.mainItems {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (max-width: 767.9px) {
    height: auto;
    padding-top: 32px;
    @media (max-height: 600px) {
      padding-top: 200px;
    }
  }
}

.item {
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 5px;
  // background-color: $secondary;

  &_active {
    background-color: $blue-active;
    @media (max-width: 767.9px) {
      background-color: transparent;
    }

    .icon {
      color: white;
    }
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: $blue-hover;
  }

  @media (max-width: 767.9px) {
    margin-bottom: 32px;
    :last-child {
      margin-bottom: 0;
    }
  }
}

.itemCollapsed {
  width: 40px;
  overflow: hidden;
  margin-left: 15px;
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    transition: none;
  }
  @media (max-width: 767.9px) {
    width: 100%;
    margin-left: 0;
  }
}

.itemCollapsedTablet {
  @media (min-width: 768px) and (max-width: 1439.9px) {
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    transition: none;
  }
}

.icon {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 24px;
  color: #a1d3f7;

  @media (max-width: 767.9px) {
    display: none;
  }
}

.itemText {
  text-decoration: none;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  @media (max-width: 1439.9px) {
    display: none;
  }
  @media (max-width: 767.9px) {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
}

.titleBlock {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  padding: 8px;
  color: $blue-icon-default;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  @media (max-width: 767.9px) {
    display: none;
  }
}

.itemsBlock {
  margin-bottom: 24px;
  @media (max-width: 767.9px) {
    margin-bottom: 0;
  }
}

.itemsBlockTablet {
  margin-bottom: 24px;
}

.titleBlockCollapsedDesktop {
  width: 71px;
  text-align: center;
  padding: 8px 0;
  @include animationSidebar();
  @media (max-width: 1439.9px) {
  }
  @media (min-width: 768px) and (max-width: 1439.9px) {
  }
}

.titleBlockCollapsedTablet {
  @media (min-width: 768px) and (max-width: 1439.9px) {
    text-align: center;
    padding: 8px 0;
  }
}

.mobileMenuClose {
  display: none;
}

@media (max-width: 767.9px) {
  .mobileMenuClose {
    display: block;
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

.backMobile {
  margin-top: 32px;
  @media (max-width: 767.9px) {
    display: none;
  }
}
