@import "src/common";

.container {
  display: flex;
}

.button {
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D3E4EE;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1B2A47;
  font-family: "Source Sans Pro", sans-serif;

  font-weight: 600;
  font-size: 14px;

  line-height: 30px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }

   @media (max-width: 767.9px) {
     margin-right: 4px;
  }
}

.buttonPoints {
  border: 1px solid transparent;
  background-color: transparent;

  &:hover {
    cursor: auto;
  }

  @media (max-width: 499.9px) {
    display: none;
  }
}

.buttonActive {
  background: #F5FBFF;
  color: #41A8ED;
}