@import "src/common";

.wrapperGradient{
  background: linear-gradient( to bottom, $green, $blue);
  border-radius: 50%;
  width: 220px;
  height: 220px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 2px;
  box-sizing: border-box;
}
.wrapperGradient.noGradient{
  background: none;
  width: auto;
  height: auto;
}
.innerBlock{
  background: white;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  box-sizing: border-box;
}
.text{
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: $gray;
  margin-bottom: 32px;
}
.wrapButton{
  width: 140px;
  margin: 0 auto;
}
.icon{
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  @media (max-width: 374.9px)  {
    margin-bottom: 6px;
  }
}
.wrapper{
  margin: auto;
  display: flex;
  flex-grow: 1;
  justify-content: center !important;
  // padding-bottom: 129px;
}
