@import "src/common";

.emptyNotifications {
  display: flex;
  height: 346px;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5A5D65;
  text-align: center;
  padding: 20px;
}

.dropdownPanel {
  background: white;
  filter: drop-shadow(4px 4px 16px #EEF0F4);
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  right: 256px;
  top: calc(100% - 8px);
  z-index: 100;
  width: 260px;
  max-width: 100%;
  @media (max-width: 1439.9px) {
    right: 240px;
  }
  @media (max-width: 767.9px) {
    right: 15px
  }
  @media (max-width: 374.9px) {
    right: 0
  }
}

.labelNotification {
  display: flex;
  background: $green;
  color: white;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  padding: 0 5px;
  border-radius: 3px;
}

.itemTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.itemDate {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $black;
  margin-right: 15px;
}

.itemText {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $black;
}

.itemNotification {
  display: block;
  margin-bottom: 8px;
  padding: 16px 24px;

  &:hover {
    cursor: pointer;
  }
}

.itemNotificationOpen {
  background: $background;
}

.seeAllContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;

  &:hover {
    cursor: pointer;
  }
}

.seeAllText {
  color: $blue;
  margin-right: 8px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.seeAllIcon {

}

.itemLeft {
  display: flex;
  align-items: center;
}